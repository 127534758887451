import { useIsMobile, useLazyRef } from '../../shared/lib/hooks.ts'
import { useAppModule } from '../../features/appContext.ts'
import { useSnapshot } from 'valtio/react'
import { cn, isKeyInObject, useUnmount } from '../../shared/lib/utils.ts'
import { classed } from '@tw-classed/react'
import { useLocation } from 'react-router-dom'
import {
  AppHeader,
  AppHeaderNavigationButton,
} from '../../features/appHeader/appHeader.tsx'
import { LessonType } from '../../shared/api/chatApi.ts'
import {
  VocabStoreContext,
  VocabView,
  useVocabStore,
} from '../../features/vocabLessons/vocabLessonView.tsx'
import { motion } from 'framer-motion'

export function VocabChat(props: { tag: string }) {
  const location = useLocation()
  const state = location.state as {
    recreateChat: boolean
    lessonType: LessonType
  } | null
  const recreateChat =
    state !== null && isKeyInObject('recreateChat', state)
      ? state.recreateChat
      : false

  const appModule = useAppModule()
  const isMobile = useIsMobile()

  const vocabLessonStoreRef = useLazyRef(() => {
    const vocabLessonStore = appModule.vocabLessonStore()
    void vocabLessonStore.newChat(props.tag, recreateChat, 'vocabulary')
    return {
      vocabLessonStore,
    }
  })

  //   const vocabLessonState = useSnapshot(
  //     vocabLessonStoreRef.current.vocabLessonStore.state,
  //   )
  //   const authState = useSnapshot(appModule.authStore.state)

  useUnmount(() => {
    appModule.speechService.dispose()
  })

  const demoComplete = Boolean(false)

  const progressBlock = isMobile ? (
    <StageProgressBlockMobile />
  ) : (
    <StageProgressBlock complete={demoComplete} />
  )

  return (
    <VocabStoreContext.Provider value={vocabLessonStoreRef.current}>
      <div className={cn('flex h-dvh w-full flex-col')}>
        {progressBlock}
        <VocabChatContainer data-testid="chat-page">
          <VocabView />
        </VocabChatContainer>
      </div>
    </VocabStoreContext.Provider>
  )
}

function VocabProgressBar({
  exerciseCount,
  currentIndex,
  isLessonCompleted,
}: {
  exerciseCount: number | null
  currentIndex: number
  isLessonCompleted: boolean
}) {
  if (exerciseCount === null) return null
  return (
    <div className="flex w-full space-x-2">
      {Array.from({ length: exerciseCount }).map((_, i) => {
        const isActive = isLessonCompleted
          ? i < currentIndex
          : i + 1 < currentIndex

        return (
          <motion.div
            key={`progress-bar-${i}`}
            className="h-6 w-full overflow-hidden rounded-full bg-blue8"
          >
            <motion.div
              className="h-full bg-blue7"
              initial={{ width: 0 }}
              animate={{ width: isActive ? '100%' : '0%' }}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
            />
          </motion.div>
        )
      })}
    </div>
  )
}

function StageProgressBlockMobile() {
  const store = useVocabStore().vocabLessonStore
  const chatState = useSnapshot(store.state)
  const isLessonCompleted = chatState.lessonCompleted
  const currentIndex = chatState.currentIndex
  const exerciseCount = chatState.exerciseCount
  return (
    <div className="fixed left-0 top-0 z-20 flex h-100 w-full items-center bg-demo-mobile-header px-16 backdrop-blur-4">
      <div className="flex items-center">
        {!isLessonCompleted && <AppHeaderNavigationButton />}
      </div>
      <div
        className={cn(
          'flex flex-1 gap-6 text-start text-18 font-bold',
          isLessonCompleted ? 'ml-24' : 'ml-16',
        )}
      >
        <VocabProgressBar
          exerciseCount={exerciseCount}
          currentIndex={currentIndex}
          isLessonCompleted={isLessonCompleted}
        />
      </div>
    </div>
  )
}

function StageProgressBlock(props: { complete: boolean; className?: string }) {
  //const store = useVocabStore().vocabLessonStore
  // const chatState = useSnapshot(store.state)
  //const appStore = useAppModule().appStore
  //const appState = useSnapshot(appStore.state)
  //const language = appState.language

  return (
    <AppHeader backButton={true} className={props.className}>
      {/* {stages && (
        <div className="mx-auto w-full max-w-800 animate-in fade-in">
          <div
            className={cn(
              'relative mb-16 flex items-center gap-12 text-center text-14 font-bold sm:mb-0 sm:text-left sm:text-20',
              'text-white',
            )}
          ></div>
          <StageProgress
            disabled={props.complete}
            className="mx-auto max-w-800 pr-12 mxs:hidden sm:pr-0"
            stages={stages}
            currentStage={chatState.currentStage}
            onStageSelected={(stage) => {
              document
                .getElementById(messageStageElementId(stage))
                ?.scrollIntoView({ behavior: 'smooth' })
            }}
          />
        </div>
      )} */}
      {!props.complete && <div className="hidden h-12 w-400 sm:block"></div>}
    </AppHeader>
  )
}

export const VocabChatContainer = classed.div(
  'flex w-full flex-1 justify-between gap-24 overflow-hidden bg-white sm:rounded-tl-8 sm:bg-white sm:px-36 sm:pt-36',
)
