import { proxy } from 'valtio'
import {
  ApiOnboardingInfo,
  ChatApi,
  ProgramItem,
  Stat,
  Student,
} from '../../shared/api/chatApi.ts'
import { groupBy, objectMap, sortBy, uniqueBy } from '../../shared/lib/utils.ts'
import { setLoading } from '../../shared/lib/storeUtils.ts'
import {
  SubscriptionService,
  SubscriptionState,
} from '../subscriptions/subscriptionService.ts'
import { AppLocalStorage } from '../../shared/lib/appLocalStorage.ts'
import { fakeProgramData } from './fakeProgramData.ts'
import { is } from '../../shared/lib/taggedUnion.ts'
import { AuthStore } from '../auth/authStore.ts'
import { convertToId } from '../../shared/lib/stringUtils.ts'

interface Cycle {
  title: string
  items: ProgramItem[]
  index: number
}
interface State {
  tourStepIndex?: number
  urlParams?: string
  userId?: number
  subscriptionState: SubscriptionState
  showTour: boolean
  programTree: {
    level: string
    items: {
      program: string
      items: Cycle[]
    }[]
  }[]
  stat: Stat
  levelPrograms: Record<string, string[] | undefined>
  programTitles: string[]
  modulesOpened: boolean
  selectedCycleTitle?: string
  data: ProgramItem[]
  info: Pick<ApiOnboardingInfo, 'goal' | 'interests' | 'profession'>
  currentProgram?: ProgramItem
  currentTopic?: string
  selectedProgram?: string
  selectedProgramIsLocked: boolean
  selectedCycles: Cycle[]
  selectedCycle?: Cycle
  selectedLevel?: string
  selectedLessonId?: number
  currentCycle?: Cycle
  name: string
  level: string
  loading: boolean
  progress: number
  isFirstLesson: boolean
}

export function getProgramUrlParams(program?: ProgramItem) {
  if (!program) {
    return ''
  }

  return `${convertToId(program.level)}/${convertToId(
    program.topic,
  )}/${convertToId(program.lesson_type)}`
}

const defaultState: State = {
  subscriptionState: SubscriptionState.inactive(),
  get showTour() {
    return this.tourStepIndex !== undefined
  },
  get urlParams() {
    if (
      !this.userId ||
      !this.currentTopic ||
      !this.currentProgram?.lesson_type
    ) {
      return undefined
    }
    if (this.tourStepIndex !== undefined) {
      return `main-tips/step-${this.tourStepIndex + 1}`
    } else {
      return getProgramUrlParams(this.currentProgram)
    }
  },
  levelPrograms: {},
  stat: {
    vocab: [],
    active: 0,
    total: 0,
    new: 0,
    completed: 0,
  },
  get progress() {
    return (
      (this.data.findIndex((x) => x.id == this.currentProgram?.id) * 100) /
      this.data.length
    )
  },
  modulesOpened: false,
  get isFirstLesson() {
    return !this.stat.active && !this.stat.completed
  },
  get selectedCycles() {
    return (
      this.programTree
        .map((x) => x.items)
        .flat()
        .find((x) => x.program == this.selectedProgram)?.items ?? []
    )
  },
  get selectedProgramIsLocked() {
    const selectedIndex = this.programTitles.indexOf(this.selectedProgram ?? '')
    const currentIndex = this.programTitles.indexOf(
      this.currentProgram?.program ?? '',
    )
    return selectedIndex > currentIndex
  },
  get programTitles() {
    return this.programTree.map((x) => x.items.map((x) => x.program)).flat()
  },
  get currentTopic() {
    return this.currentProgram?.topic
  },
  get selectedCycle() {
    return this.selectedCycles.find((x) => x.title == this.selectedCycleTitle)
  },
  programTree: [],
  data: [],
  name: '',
  level: '',
  info: {
    interests: [],
    goal: '',
    profession: '',
  },
  loading: true,
  selectedLevel: '',
}

export class ProgramStore {
  state: State

  constructor(
    public registration: boolean,
    private chatsApi: ChatApi,
    private subscriptionService: SubscriptionService,
    private appStorage: AppLocalStorage,
    private authStore: AuthStore,
  ) {
    this.state = proxy<State>(defaultState)
  }

  nextTourStep() {
    if (this.state.tourStepIndex === undefined) {
      return
    }
    this.state.tourStepIndex += 1
  }

  setLoading(loading: boolean) {
    this.state.loading = loading
  }

  getModuleStats() {
    const currentTopicNumber = this.state.programTitles.indexOf(
      this.state.currentProgram?.program ?? '',
    )
    const topicCount = this.state.programTitles.length
    const topicProgress =
      topicCount > 0 ? (currentTopicNumber * 100) / topicCount : 0
    const [wordsLearnedCount, wordsTotalCount] = this.state.stat.vocab.reduce(
      ([x1, y1], x) => [x1 + x.learned_count, y1 + x.total_count],
      [0, 0],
    )
    const wordProgress =
      wordsTotalCount > 0 ? (wordsLearnedCount * 100) / wordsTotalCount : 0
    return {
      currentTopicNumber,
      topicCount,
      topicProgress,
      wordsLearnedCount,
      wordsTotalCount,
      wordProgress,
    }
  }

  async requestAllData(): Promise<
    [ProgramItem[], Student, ProgramItem[], Stat, SubscriptionState]
  > {
    if (this.registration) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return fakeProgramData
    }
    return Promise.all([
      this.chatsApi.userPrograms(),
      this.chatsApi.getAccount(),
      this.chatsApi.allPrograms(),
      this.chatsApi.stat(),
      this.subscriptionService.getSubscriptionState(),
    ])
  }

  async loadPrograms() {
    const [programs, account, allPrograms, stat, subscriptionState] =
      await setLoading(this.state, () => this.requestAllData())

    if (programs.length === 0) {
      return
    }
    this.state.subscriptionState = subscriptionState
    const data = sortBy(programs, (x) => x.order)
    const programTree = Object.entries(groupBy(data, (x) => x.level)).map(
      ([level, items]) => {
        const group = Object.entries(groupBy(items, (x) => x.program)).map(
          ([program, items]) => ({
            program,
            items: Object.entries(
              groupBy(items, (x) => x.program + ';' + x.topic),
            ).map(([title, items], index) => ({
              title: title.split(';')[1],
              items,
              index,
            })),
          }),
        )

        return {
          level,
          items: group,
        }
      },
    )

    this.state.stat = stat

    this.state.levelPrograms = objectMap(
      groupBy(allPrograms, (x) => x.level),
      (item) =>
        uniqueBy(
          item.map((x) => x.program),
          (x) => x,
        ),
    )
    this.state.data = data
    this.state.programTree = programTree

    this.state.level = account.level
    this.state.name = account.name
    const currentProgram =
      account.session_metadata === null ||
      account.session_metadata.lessonType !== 'lesson' ||
      account.session_metadata.programTag === 'demo_lesson'
        ? data.find((x) => x.status != 'completed')
        : data.find((x) => x.tag === account.session_metadata?.programTag)
    this.state.currentProgram = currentProgram
    this.state.selectedCycleTitle = currentProgram?.topic
    this.state.selectedProgram = currentProgram?.program
    this.state.selectedLevel = currentProgram?.level

    if (!this.state.selectedLessonId) {
      this.state.selectedLessonId = currentProgram?.id
    }

    this.state.currentCycle = this.state.selectedCycles.find(
      (x) => x.title == this.state.currentProgram?.topic,
    )

    const onboardingInfo = account.onboarding_info
    this.state.info = {
      goal: onboardingInfo.goal,
      profession: onboardingInfo.profession,
      interests: onboardingInfo.interests,
    }
    if (this.authStore.state.user) {
      this.state.userId = this.authStore.state.user.user_id
    }
    const showTour =
      !this.registration &&
      !this.appStorage.get('hideProgramTour') &&
      is(subscriptionState, 'active')
    if (showTour) {
      this.state.tourStepIndex = 0
    }
  }

  showTour() {
    this.state.tourStepIndex = 0
  }

  endTour() {
    this.state.tourStepIndex = undefined
  }
  hideTour() {
    this.state.tourStepIndex = undefined
    this.appStorage.set('hideProgramTour', true)
  }

  setSelectedCycleTitle(topic: string) {
    this.state.selectedCycleTitle = topic
  }

  setSelectedLesson(lessonId: number) {
    this.state.selectedLessonId = lessonId
  }

  setSelectedProgram(topic: string) {
    this.state.selectedProgram = topic
    this.state.selectedCycleTitle = this.state.selectedCycles[0]?.title
  }

  setSelectedLevel(level: string) {
    this.state.selectedLevel = level
    const program = this.state.programTree.find(
      (program) => program.level === level,
    )
    this.state.selectedProgram = program?.items[0].program
    this.state.selectedCycleTitle = program?.items[0].items[0].title
  }

  toggleModulesOpen() {
    this.state.modulesOpened = !this.state.modulesOpened
  }
}
